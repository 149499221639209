import React, { useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
})

const auth = firebase.auth();
const firestore = firebase.firestore();


function App() {

  const [user] = useAuthState(auth);

  return (
    <div className="text-center max-w-[728px] m-auto">
      <header className="h-[10vh] min-h-[50px] text-white fixed w-[100%] m-w-[728px] top-0 flex items-center z-[99] p-[10px] box-border pl-[10px]">
        <h1 className="font-bold text-4xl">MEGACORD</h1>
        <SignOut />
      </header>

      <section className="flex flex-col justify-center m-h-[100vh] h-screen bg-gray-800">
        {user ? <ChatRoom /> : <SignIn />}
      </section>

    </div>
  );
}

function SignIn() {

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider);
  }

  const signInWithGithub = () => {
    const provider = new firebase.auth.GithubAuthProvider();
    auth.signInWithPopup(provider);
  }

  return (
    <>
      <button className="sign-in-google hover:bg-blue-400 transition-all duration-200" onClick={signInWithGoogle}>Sign in with Google </button>
      <button className="sign-in-github hover:bg-gray-900 transition-all duration-200" onClick={signInWithGithub}>Sign in with Github</button>
      <p className="mx-auto text-center mt-2 bg-gray-800 shadow-lg">Megacord © Discord but one channel.</p>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="hover:bg-gray-600 transition-all duration-200 bg-gray-700 rounded-lg ml-2 md:ml-8" onClick={() => auth.signOut()}>Sign Out</button>
  )
}


function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(25);

  const [messages] = useCollectionData(query, { idField: 'id' });

  let [formValue, setFormValue] = useState('');


  const sendMessage = async (e) => {
    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;

    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }

  return (<>
    <main>

      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>

    <form onSubmit={sendMessage} className="">

      <input className="" value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Type your message here..." />

      <button className="bg-green-500 hidden md:flex p-8" type="submit" disabled={!formValue}>Send</button>

    </form>
  </>)
}


function ChatMessage(props) {
  const { text, uid, photoURL } = props.message;

  const messageClass = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (<>
    <div className={`message ${messageClass}`} >
      <img alt="Profile" src={photoURL || 'https://api.adorable.io/avatars/23/abott@adorable.png'} />
      <p className="text-xs md:text-lg max-w-[280px] md:max-w-[400px] font-semibold md:font-normal">{text}</p>
    </div>
  </>)
}


export default App;